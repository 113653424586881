@each $size, $length in $spacers {
    .gap-#{$size} {
      gap: $length !important;
    }

    .gap-x-#{$size} {
        column-gap: $length !important;
    }

    .gap-y-#{$size} {
        row-gap: $length !important;
    }
  }