$primary: #de7a01;

// Fonts
$font-size-base: 0.8rem; // Assumes the browser default, typically `16px`
$font-size-sm: 0.8rem;
$font-size-root: 14px;
$enable-text-optimize-legibility: true; // you can set it to false to increase rendering performance

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1366px
);