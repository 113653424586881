.block {
  position: relative;
  display: flex;
  flex-direction: column;

  &.block-background-image {
    background-size: cover;
    background-position: center center;
  }

  .block-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
  }
}