//
// Dropdown
// --------------------------------------------------

.dropdown-menu {
    box-shadow: $dropdown-box-shadow;

    &.dropdown-menu-lg {
        min-width: 300px;
    }
}