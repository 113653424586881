//
// Custom Forms
// --------------------------------------------------

// Custom control variant
@mixin custom-control-variant($background, $background-checked) {
    .custom-control-input {
        &:checked ~ .custom-control-label::before {
            background-color: $background-checked;
            border-color: $background-checked;
        }

        &:focus ~ .custom-control-label::before {
            box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba($background-checked, .25);
        }

        &:focus:not(:checked) ~ .custom-control-label::before {
            border-color: $background-checked;
        }

        &:not(:disabled):active~.custom-control-label::before {
            background-color: lighten($background-checked, 20%);
            border-color: lighten($background-checked, 20%);
        }
    }

    .custom-control-label::before {
        background-color: $background;
        border-color: darken($background, 25%);
    }

    &.custom-checkbox {
        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: $background-checked;
        }

        .custom-control-input:indeterminate ~ .custom-control-label::before {
            background-color: $background-checked;
        }
    }

    &.custom-radio {
        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: $background-checked;
        }
    }

    &.custom-switch {
        .custom-control-label::after {
            background-color: rgba($background-checked,.6);
        }
    }
}
