@charset "utf-8";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// Variables
@import 'variables';

// Theme
@import 'oneui';
@import 'node_modules/bootstrap-vue/src/index.scss';

// Components
@import 'components/gap';
@import 'components/block';
@import 'components/header';
@import 'components/editor';

// Libraries
@import 'simplebar';
@import '~animate.css';

body {
  background: #E3DED6;
}

#main-container {
  position: relative;
  overflow: hidden;

  .background-video video {
    position: absolute;
    top: 50%; 
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%; 
    min-height: 100%; 
    width: auto; 
    height: auto;
    z-index: -1000; 
    overflow: hidden;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(222, 122, 1, 0.5);
    z-index: -999; 
  }

  .vue-notification-group {
    top: $header-height !important;

    @include media-breakpoint-up(lg) {
      top: 4.5rem !important;
    }
  }
}

.bg-primary {
  &-5 { background-color: rgba(222,122,1,.05) !important; }
  &-10 { background-color: rgba(222,122,1,.1) !important; }
  &-25 { background-color: rgba(222,122,1,.25) !important; }
  &-50 { background-color: rgba(222,122,1,.5) !important; }
  &-75 { background-color: rgba(222,122,1,.75) !important; }
  &-90 { background-color: rgba(222,122,1,.9) !important; }
  &-95 { background-color: rgba(222,122,1,.95) !important; }
}

footer.login {
  background-color: #1f2024;
  color: #aaa;
  padding: 60px 0 20px;
}

footer.login .widget-title {
  position: relative;
  opacity: 0.99;
  display: inline-block !important;
  width: auto;
  font-size: 15px;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 0px 10px;
  color: #fff;
}

footer.login .widget-title:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111;
  z-index: -1;
}

footer.login .widget-title:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 70%;
  height: 100%;
  background-color: #111;
  -webkit-transform: skew(-40deg) translateX(14px);
  -ms-transform: skew(-40deg) translateX(14px);
  transform: skew(-40deg) translateX(14px);
  z-index: -1;
}

footer.login .widget-title h4 {
  font-size: 15px;
  font-weight: 600;
  line-height: 27px;
  margin: 0;
  color: #fff;
}

footer.login .widget-content {
  padding: 0 5px;
  font-size: 14px;
}

footer.login .widget-content p {
  color: #aaa;
}
footer.login .widget-content a {
  color: #de7a01;
}

footer.login .widget-content img {
  padding: 5px 0;
  max-width: 75%;
}