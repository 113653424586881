// #e1e1e1

.ql-toolbar {
  &.ql-snow {
    border: 1px solid #e1e1e1;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out;
  }

  &.is-invalid {
    border-color: $danger;
  }
}

.ql-container {
  &.ql-snow {
    border: 1px solid #e1e1e1;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out;
  }

  &.is-invalid {
    border-color: $danger;
  }
}